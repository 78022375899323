import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCombobox } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomYubinbangoAddressLookUp } from 'ui/forms/custom/types';
import { useTranslationFieldOrDefault } from 'hooks/useTranslationFieldOrDefault';
import { useSiteConfig } from 'hooks/useSiteConfig';

import { buildName } from '../helpers';

type SelectProps = {
  id: string;
  value: string | number;
  codeInfo: any[];
};
export type AddressResponseYubinbango = {
  region_id: string | number | undefined;
  region: string | undefined;
  locality: string | undefined;
  street: string | undefined;
  extended: string | undefined;
};
export const EMPTY_ADDRESS_RESPONSE: AddressResponseYubinbango = {
  region_id: undefined,
  region: undefined,
  locality: undefined,
  street: undefined,
  extended: undefined,
};

const CustomFormYubinbangoAddressLookUp = ({
  field,
  required = false,
  prefix,
}: {
  field: CustomYubinbangoAddressLookUp;
  required?: boolean;
  prefix?: string;
}) => {
  const { register, trigger, setValue, watch } = useFormContext();
  const defaultValue = useRef(watch(field.name));

  const t = useTranslate();
  const { resolveInputError } = useFormField(field);

  const getErrorMessageOrDefault = useTranslationFieldOrDefault();

  const getName = (field: string) => {
    return prefix ? `${prefix}.${field}` : field;
  };
  const url = 'https://yubinbango.github.io/yubinbango-data/data/';
  const { addressStates } = useSiteConfig();

  const onOptionSelect = async address => {
    const [regionId, locality, street, extended] = address.codeInfo ?? [];

    let addressRes: AddressResponseYubinbango = EMPTY_ADDRESS_RESPONSE;

    if (regionId && locality) {
      addressRes = {
        region_id: regionId ?? regionId,
        region: addressStates['JP']
          ? addressStates['JP'].find(state => state.value === regionId)?.name
          : undefined,
        locality: locality,
        street: street ?? street,
        extended: extended ?? street,
      };
    }
    setValue(
      buildName('postalCode', prefix),
      address.value ? `${address.value.substring(0, 8)}` : ''
    );
    setValue(
      buildName('city', prefix),
      addressRes?.locality && addressRes?.street
        ? addressRes.locality + '' + addressRes.street
        : ''
    );
    setValue(buildName('stateCode', prefix), addressRes?.region ?? '');
    setValue(buildName('address1', prefix), '');

    trigger([
      getName('stateCode'),
      getName('city'),
      getName('postalCode'),
      getName('address1'),
    ]);
  };

  const filter = ({ value, setOptions }) => {
    setValue(buildName('stateCode', prefix), '');
    setValue(buildName('city', prefix), '');
    trigger([getName('stateCode'), getName('city')]);

    const cleanedUpValue = value
      .replace(/[０-９]/g, (s: string) =>
        String.fromCharCode(s.charCodeAt(0) - 65248)
      )
      ?.match(/\d/g)
      ?.join('');

    if (cleanedUpValue && cleanedUpValue?.length > 2) {
      fetch(url + cleanedUpValue?.substring(0, 3) + '.js')
        .then(response => response.text())
        .then(result => {
          const matcher = result.match(/({".*"]})/);
          if (matcher) {
            const json = JSON.parse(matcher[0]);
            const arrayFilter: Array<SelectProps> = [];
            Object.entries(json).forEach(([key, value]) => {
              if (key.startsWith(cleanedUpValue)) {
                const codeInfo = value as any[];
                arrayFilter.push({
                  value: `${key.slice(0, 3)}-${key.slice(3)} ${codeInfo[1]}${
                    codeInfo[2]
                  }`,
                  id: key,
                  codeInfo,
                });
              }
            });
            if (arrayFilter.length === 1) onOptionSelect(arrayFilter[0]);
            setOptions(arrayFilter);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(
            `Error while fetching yubinbango result for postalCode: ${value}`,
            error
          );
        });
    }
  };

  return (
    <InputCombobox
      defaultValue={defaultValue.current}
      key={field.key}
      name={field.name}
      label={field.label}
      id={field.id}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder || ''}
      maxLength={field.maxLength}
      required={required}
      ref={register({
        validate: value => {
          if (
            value &&
            field.pattern &&
            !new RegExp(field.pattern).test(value)
          ) {
            return field.patternError || t('invalidInput');
          }
        },
        required:
          required &&
          getErrorMessageOrDefault(
            field.name.substring(field.name.indexOf('.') + 1),
            'requiredField'
          ),
      })}
      errorText={resolveInputError()}
      typeahead={true}
      onOptionSelect={onOptionSelect}
      filter={filter}
      crossOrigin={undefined}
    />
  );
};
export default React.memo(CustomFormYubinbangoAddressLookUp);
